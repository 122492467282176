import React from "react"
import Layout from "../../../components/member/Layout"
import Mountain from "../../../components/member/group/Mountain"

export default () => {
  const pageSlug = "mountain"
  const pageTitle = "山学部"
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Mountain pageTitle={pageTitle} />
    </Layout>
  )
}
