import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  lead: {
    fontSize: "0.85rem",
    margin: "0.5rem 0 1rem",
  },
}))

export default ({ pageTitle }) => {
  const classes = useStyles()
  return (
    <>
      <div className="container">
        <h1>{pageTitle}</h1>
        <div className={classes.lead}>
          リーダー：だいちゃん／カメラマン：はるか氏
        </div>

        <div style={{ padding: "2rem", background: "#ddd" }}>
          一年のざっくりプランを入れよかな
          <br />
          <br />
          10月：紅葉
          <br />
          11月：山登り
          <br />
          2月：釣り解禁
          <br />
          とか
        </div>
        <Link
          to="./member/group"
          style={{ color: "#497988", marginTop: "4rem", display: "block" }}
        >
          ←グループ一覧へ戻る
        </Link>
      </div>
    </>
  )
}
